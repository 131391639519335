import React from 'react';
import PropTypes from 'prop-types';

const Login = props => (
  <nav className = "login">
    <h2>Inventory login</h2>
    <p>Sign in to manage your store's inventory.</p>
    <button className="github" onClick={() => props.authenticate("Github")}>
      Log In With GitHub
    </button>

    <button className="twitter" onClick={ () => props.authenticate("Email") }>
      Log in with email
    </button>
  </nav>
);

Login.propTypes = {
  authenticate: PropTypes.func.isRequired
}

export default Login;